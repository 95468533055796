export const FETCH_SESSION_RESERVATIONS_LIST_START =
  'FETCH_SESSION_RESERVATIONS_LIST_START';
export const FETCH_SESSION_RESERVATIONS_LIST_SUCCESS =
  'FETCH_SESSION_RESERVATIONS_LIST_SUCCESS';
export const FETCH_SESSION_RESERVATIONS_LIST_FAIL =
  'FETCH_SESSION_RESERVATIONS_LIST_FAIL';

export const FETCH_USER_RESERVATIONS_LIST_START =
  'FETCH_USER_RESERVATIONS_LIST_START';
export const FETCH_USER_RESERVATIONS_LIST_SUCCESS =
  'FETCH_USER_RESERVATIONS_LIST_SUCCESS';
export const FETCH_USER_RESERVATIONS_LIST_FAIL =
  'FETCH_USER_RESERVATIONS_LIST_FAIL';

export const DELETE_RESERVATION_START = 'DELETE_RESERVATION_START';
export const DELETE_RESERVATION_SUCCESS = 'DELETE_RESERVATION_SUCCESS';
export const DELETE_RESERVATION_FAIL = 'DELETE_RESERVATION_FAIL';

export const RESET_RESERVATION_STORE = 'RESET_RESERVATION_STORE';

export const LOGOUT = 'LOGOUT';

export type ReservationActionTypes =
  | typeof FETCH_SESSION_RESERVATIONS_LIST_START
  | typeof FETCH_SESSION_RESERVATIONS_LIST_SUCCESS
  | typeof FETCH_SESSION_RESERVATIONS_LIST_FAIL
  | typeof FETCH_USER_RESERVATIONS_LIST_START
  | typeof FETCH_USER_RESERVATIONS_LIST_SUCCESS
  | typeof FETCH_USER_RESERVATIONS_LIST_FAIL
  | typeof DELETE_RESERVATION_START
  | typeof DELETE_RESERVATION_SUCCESS
  | typeof DELETE_RESERVATION_FAIL
  | typeof RESET_RESERVATION_STORE
  | typeof LOGOUT;

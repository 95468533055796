import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import 'moment/locale/lt';
import { Coupon } from '../../domain/Coupon';

const LoginPage = React.lazy(
  () => import('../../pages/Public/Auth/LoginPage/LoginPage'),
);

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/UsersListPage/UsersListPage'),
);

const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/UserCreatePage/UserCreatePage'),
);

const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/UserEditPage/UserEditPage'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const LanguagesListPage = React.lazy(
  () => import('../../pages/Admin/Language/LanguagesListPage'),
);

const SessionTypeListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/SessionType/SessionTypeListPage/SessionTypeListPage'
    ),
);

const SubscriptionTypeListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/SubscriptionType/SubscriptionTypeListPage/SubscriptionTypeListPage'
    ),
);

const CouponListPage = React.lazy(
  () => import('../../pages/Admin/Coupon/CouponListPage/CouponListPage'),
);

const Sessions = React.lazy(
  () => import('../../pages/Admin/Sessions/Sessions'),
);

const PaymentListPage = React.lazy(
  () => import('../../pages/Admin/Payment/PaymentListPage/PaymentListPage'),
);

const RegistrationPage = React.lazy(
  () => import('../../pages/Public/Auth/RegistrationPage/RegistrationPage'),
);

const ForgotPasswordPage = React.lazy(
  () => import('../../pages/Public/Auth/ForgotPasswordPage/ForgotPasswordPage'),
);

const ResetPasswordPage = React.lazy(
  () => import('../../pages/Public/Auth/ResetPasswordPage/ResetPasswordPage'),
);

const AboutUsPage = React.lazy(
  () => import('../../pages/Public/AboutUsPage/AboutUsPage'),
);

const ContactUsPage = React.lazy(
  () => import('../../pages/Public/ContactUsPage/ContactUsPage'),
);

const SubscriptionsPage = React.lazy(
  () => import('../../pages/Public/SubscriptionsPage/SubscriptionsPage'),
);

const PublicCouponsPage = React.lazy(
  () => import('../../pages/Public/PublicCouponsPage/PublicCouponsPage'),
);

const FaqPage = React.lazy(() => import('../../pages/Public/FaqPage/FaqPage'));

const ReservationPage = React.lazy(
  () => import('../../pages/Public/ReservationPage/ReservationPage'),
);

const GeneralInformationPage = React.lazy(
  () => import('../../pages/Public/Profile/MySettingsPage/MySettingsPage'),
);

const MyReservationsPage = React.lazy(
  () =>
    import('../../pages/Public/Profile/MyReservationsPage/MyReservationsPage'),
);

const MySubscriptionsPage = React.lazy(
  () =>
    import(
      '../../pages/Public/Profile/MySubscriptionsPage/MySubscriptionsPage'
    ),
);

const PaymentsHistoryPage = React.lazy(
  () =>
    import(
      '../../pages/Public/Profile/PaymentsHistoryPage/PaymentsHistoryPage'
    ),
);

const MyCouponsPage = React.lazy(
  () => import('../../pages/Public/Profile/MyCouponsPage/MyCouponsPage'),
);

const HomePage = React.lazy(
  () => import('../../pages/Public/HomePage/HomePage'),
);

const UserChangePasswordPage = React.lazy(
  () =>
    import('../../pages/Public/UserChangePasswordPage/UserChangePasswordPage'),
);

const TermsOfUsePage = React.lazy(
  () => import('../../pages/Public/TermsOfUsePage/TermsOfUsePage'),
);

const PrivacyPolicyPage = React.lazy(
  () => import('../../pages/Public/PrivacyPolicyPage/PrivacyPolicyPage'),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  currentUser: User | null;
  onLanguagesInit: () => void;
  validCoupon: Coupon | null;
};

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  onLanguageFetch,
  language,
  selectedLocale,
  onSelectLocale,
  currentUser,
  onLanguagesInit,
  validCoupon,
}: Props) => {
  const [isInitialUserLoaded, setIsInitialUserLoaded] = useState(false);

  useEffect(() => {
    if (isCurrentUserLoading) {
      setIsInitialUserLoaded(true);
    }
  }, [isCurrentUserLoading]);

  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (validCoupon) {
      onFetchCurrentUser();
    }
  }, [validCoupon]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const intervalId = setInterval(() => {
      onFetchCurrentUser();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentUser]);

  useEffect(() => {
    moment.locale(DEFAULT_LOCALE);
    onLanguageFetch(selectedLocale ?? DEFAULT_LOCALE);
    onLanguagesInit();
    onSelectLocale(selectedLocale ?? DEFAULT_LOCALE);
  }, []);

  useEffect(() => {
    if (selectedLocale) {
      onLanguageFetch(selectedLocale);
    }
  }, [selectedLocale]);

  const mappedTranslations = language?.translations?.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const getRoutes = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Route path={routes.homepage} element={<HomePage />} />
          <Route path={routes.newsletter} element={<HomePage />} />
          <Route path={routes.openingOffer} element={<HomePage />} />
          <Route path={routes.termsOfUse} element={<TermsOfUsePage />} />
          <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.login} />}
          />
          <Route
            path={routes.registrationConfirmation}
            element={<HomePage />}
          />
          <Route path={routes.registration} element={<RegistrationPage />} />
          <Route
            path={routes.forgotPassword}
            element={<ForgotPasswordPage />}
          />
          <Route path={routes.passwordReset} element={<ResetPasswordPage />} />
          <Route path={routes.aboutUs} element={<AboutUsPage />} />
          <Route path={routes.contactUs} element={<ContactUsPage />} />
          <Route path={routes.subscriptions} element={<SubscriptionsPage />} />
          <Route path={routes.publicCoupons} element={<PublicCouponsPage />} />
          <Route path={routes.faq} element={<FaqPage />} />
          <Route path={routes.login} element={<LoginPage />} />
          <Route path="*" element={<Navigate to={routes.login} />} />
        </>
      );
    }

    if (isAuthenticated && currentUser?.role === Roles.ADMIN) {
      return (
        <>
          <Route path={routes.users.create} element={<UserCreatePage />} />
          <Route path={routes.users.edit} element={<UserEditPage />} />
          <Route path={routes.users.list} element={<UsersListPage />} />
          <Route path={routes.translations} element={<TranslationsPage />} />
          <Route path={routes.languages} element={<LanguagesListPage />} />
          <Route path={routes.sessionTypes} element={<SessionTypeListPage />} />
          <Route path={routes.coupons} element={<CouponListPage />} />
          <Route path={routes.sessions} element={<Sessions />} />
          <Route path={routes.reservation} element={<ReservationPage />} />
          <Route path={routes.payments.list} element={<PaymentListPage />} />
          <Route
            path={routes.myReservations}
            element={<MyReservationsPage />}
          />
          <Route
            path={routes.mySubscriptions}
            element={<MySubscriptionsPage />}
          />
          <Route
            path={routes.paymentsHistory}
            element={<PaymentsHistoryPage />}
          />
          <Route path={routes.profile} element={<GeneralInformationPage />} />
          <Route path={routes.myCoupons} element={<MyCouponsPage />} />
          <Route path={routes.homepage} element={<HomePage />} />
          <Route path={routes.newsletter} element={<HomePage />} />
          <Route path={routes.aboutUs} element={<AboutUsPage />} />
          <Route path={routes.contactUs} element={<ContactUsPage />} />
          <Route path={routes.subscriptions} element={<SubscriptionsPage />} />
          <Route path={routes.publicCoupons} element={<PublicCouponsPage />} />
          <Route path={routes.faq} element={<FaqPage />} />
          <Route
            path={routes.subscriptionTypes}
            element={<SubscriptionTypeListPage />}
          />
          <Route path="*" element={<Navigate to={routes.sessions} />} />
        </>
      );
    }

    return (
      <>
        <Route path={routes.reservation} element={<ReservationPage />} />
        <Route path={routes.myReservations} element={<MyReservationsPage />} />
        <Route
          path={routes.mySubscriptions}
          element={<MySubscriptionsPage />}
        />
        <Route
          path={routes.paymentsHistory}
          element={<PaymentsHistoryPage />}
        />
        <Route path={routes.myCoupons} element={<MyCouponsPage />} />
        <Route path={routes.profile} element={<GeneralInformationPage />} />
        <Route path={routes.homepage} element={<HomePage />} />
        <Route path={routes.newsletter} element={<HomePage />} />
        <Route path={routes.aboutUs} element={<AboutUsPage />} />
        <Route path={routes.contactUs} element={<ContactUsPage />} />
        <Route path={routes.subscriptions} element={<SubscriptionsPage />} />
        <Route path={routes.publicCoupons} element={<PublicCouponsPage />} />
        <Route path={routes.faq} element={<FaqPage />} />
        <Route path={routes.termsOfUse} element={<TermsOfUsePage />} />
        <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
        <Route
          path={routes.passwordChange}
          element={<UserChangePasswordPage />}
        />
        <Route path="*" element={<Navigate to={routes.profile} />} />
      </>
    );
  };

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && isInitialUserLoaded && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout isAuthenticated={isAuthenticated}>
                <Routes>{getRoutes()}</Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  currentUser: state.user.currentUser,
  validCoupon: state.coupon.validCoupon,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
